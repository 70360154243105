/* ==================================================================================================================
 * OpenGoSim Bluebell: app/bramble/runs/runs.interfaces.ts
 * Copyright 2017-2018 TotalSim Ltd
 * The contents of this file are NOT for redistribution
 * See AUTHORS for list of developers on project
 * ================================================================================================================== */
import { FluidProperty } from './fluid-properties.interfaces';
import { Grid } from './grids.interfaces';
import { MaterialProperty } from './material-properties.interfaces';
import { OneDPlotConfig } from './1d-plots.interfaces';
import { GeneralFile } from './general-files-properties.interfaces';

export type FileImportFile = GeneralFile | Grid | FluidProperty | MaterialProperty;

export interface FileImport {
  file_object: FileImportFile;
  file_type: string;
  filename: string;
  uuid: string;
  parent_uuid: string;
  nruf: number;
  near_matches: FileImportFile[];
  is_text: boolean;
  is_small: boolean;
  auto_linked: boolean;
}
export const fileImportUrl = 'input_decks/file_import/';

export interface InputDeck {
  errors: string;
  data: string[];
  file_imports: FileImport[];
  name: string;
  original_file: string;
  parsed_file_imports: boolean;
  parsed_full_input_deck: boolean;
  warnings: string[];
  is_text: boolean;
  is_small: boolean;
}

export interface Observer {
  name: string;
  type: string;
  variables: ObserverVariable[];
}

export interface ObserverVariable {
  name: string;
  uuid: string;
}

export const variablesUrl = 'simulations/variables/';

interface RunMetadata {
  plots_1d?: OneDPlotConfig;
  heartbeat: {received: string, mtime: string};
  start_time: string;
  status_message: string;
}

export interface Run {
  console_log_tail?: string;
  description?: string;
  input_deck?: InputDeck;
  input_zip?: string;
  metadata?: RunMetadata;
  name?: string;
  number?: number;
  observers?: Observer[];
  output_zip?: string;
  project?: string;
  project_name?: string;
  sub_project?: string;
  sub_project_name?: string;
  status?: string;
  uuid?: string;
  hardware_used?: string;
  cores_used?: number;
  output_name?: string;
}
export const runsUrl = 'simulations/runs/';
